import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Nav from './nav';
import Content from './content';
import Footer from './footer';
import './layout.scss';

const Layout = ({ children, fullwidth, textblock }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteUrl,
          title
        }
      }
    }
  `);

  return (
    <div className="grid">
      <Nav siteTitle={data.site.siteMetadata.title} siteUrl={data.site.siteMetadata.siteUrl} />
        <Content fullwidth={fullwidth} textblock={textblock}>{children}</Content>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
