/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { decodeHTML } from 'entities';
import FavIcon from '../images/icon.svg';

function SEO({ description, lang, meta, title, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description

  const [metaData, setMetaData] = useState(meta);
  const decodedTitle= decodeHTML(title);

  useEffect(() => {

    let metaTags = [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        name: `keywords`,
        content: keywords
      }
    ];

    if (navigator.userAgent.toLowerCase().includes('facebookexternalhit')) {
      metaTags = [
        ...metaTags,
        {
          property: `og:title`,
          content: decodedTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        }
      ];
    }
  
    if (navigator.userAgent.toLowerCase().includes('twitterbot')) {
      metaTags = [
        ...metaTags,
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: decodedTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        }
      ];
    }

    setMetaData(metaTags.filter(item => item.content));
  // eslint-disable-next-line 
  }, []);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={
        [
          {
            rel: 'icon', 
            type: 'image/svg+xml', 
            href: FavIcon
          }
        ]
      }
      title={decodedTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaData}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
