import React from 'react';

export default props => {
  
  return (
    <a
        className={props.className}
        href={props.href}
        rel={
          props.external
            ? 'noopener nofollow'
            : props.rel
              ? props.rel
              : undefined
          }
        target={props.external ? '_blank' : undefined}
        title={
          props.title
            ? props.title
            : typeof props.children === 'string'
              ? props.children
              : undefined
        }
    >
        {props.children}
    </a>
  );
};