import React from 'react';
import romanize from 'romanize';

import Anchor from './anchor';
import { Instagram, Twitter, YouTube } from './icons';
import './footer.scss';

const Footer = () => {
  const fullYear = new Date().getFullYear();
  const romanizedYear = romanize(fullYear);

  return (
    <footer>
      <ul className="inline unstyled">
        <li title={`${fullYear} © Nutriot Recordings`}>{`${romanizedYear} © Nutriot Recordings`}</li>
        <li>
          <Anchor
            href="https://instagram.com/nutriot"
            rel="me"
            title="Follow Nutriot Recordings on Instagram"
            external="true"
          >
            <Instagram />
          </Anchor>
          
          <Anchor
            href="https://twitter.com/nutriot"
            rel="me"
            title="Follow Nutriot Recordings on Twitter"
            external="true"
          >
            <Twitter />
          </Anchor>

          <Anchor
            href="https://youtube.com/c/NutriotRecordings"
            rel="me"
            title="Follow Nutriot Recordings on YouTube"
            external="true"
          >
            <YouTube />
          </Anchor>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;